import { useEffect, useState } from 'react';
import './App.css';
import ParticlesJS from 'particlesjs';

function App() {
  const [menuState, setMenuState] = useState(false);

  const leadership = [
    {
      id: 1,
      photoUrl: 'chip.png',
      email: 'chip@brasherholdings.com',
      name: {
        first: 'Chip',
        last: 'Brasher',
      },
      title: 'CEO',
      description:
        'Chip has 20 years of experience in banking, construction, and both commercial andresidential real estate. His experience in multi-family and single-family real estate development and entitlement are foundational to the success of Brasher Holdings.',
    },
    {
      id: 2,
      photoUrl: 'jay.png',
      email: 'jay@brasherholdings.com',
      name: {
        first: 'Jay',
        last: 'Sawyer',
      },
      title: 'President',
      description:
        'Jay is experienced Real Estate professional with a 16+ years of experience in commercial and residential real estate development. Skilled in Portfolio Management, Negotiation, Land Acquisitions, Development, Entitlement, Budgeting, Sales, Retail, and Leadership.',
    },
    // {
    //   id: 3,
    //   photoUrl: "craig.png",
    //   email: "clawrence@balch.com",
    //   name: {
    //     first: "Craig",
    //     last: "Lawrence",
    //   },
    //   title: "Of Counsel",
    //   description:
    //     "Craig is a partner at the law firm of Balch & Bingham LLP. He represents banks and developers in ground-up real estate development projects, which includes acquisition, leasing, and development.",
    // },
  ];

  useEffect(() => {
    ParticlesJS.init({
      selector: '.background',
      color: ['#08145d', '#ffffff'],
      speed: 0.05,
      maxParticles: 50,
      connectParticles: true,
      responsive: [
        {
          breakpoint: 800,
          options: {
            color: ['#08145d', '#ffffff'],
            maxParticles: 10,
          },
        },
      ],
    });
  }, []);

  return (
    <div className='App'>
      <canvas className='background' width='100%'></canvas>

      <div className='header'>
        <img
          src={`${process.env.PUBLIC_URL}/assets/logo.png`}
          className='logo'
          alt='Logo'
        />
        <button
          className='header-button-open-button'
          onClick={() => {
            setMenuState(true);
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/menu.png`}
            className='header-button-open-button-icon'
            alt='Menu button'
          />
        </button>
        <div
          className={`header-buttons ${
            menuState ? 'open' : 'collapsed'
          }`}
        >
          <button
            onClick={() => {
              setMenuState(false);
            }}
            className='header-button-close-button'
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/menu-close.png`}
              className='header-button-open-button-icon'
              alt='Menu button'
            />
          </button>
          <a href='#about' className='header-button'>
            ABOUT
          </a>
          <a href='#leadership' className='header-button'>
            LEADERSHIP
          </a>
          <a href='#contact' className='header-button'>
            CONTACT
          </a>
        </div>
      </div>
      <div id='body'>
        <div
          id='threshold-left-image'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/a.png`,
          }}
        ></div>
        <div id='slogan-wrapper'>
          <img
            src={`${process.env.PUBLIC_URL}/assets/slogan.png`}
            alt='Slogan'
            id='threshold-slogan'
          />
        </div>
        <div
          id='about-right-image'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/b.png`,
          }}
        ></div>
        <div id='about'>
          <div id='about-box'>
            <div className='section-heading'>about</div>
            <div className='section-verbiage'>
              <span className='emphasis-secondary'>
                Brasher Holdings
              </span>{' '}
              is bringing innovative real estate development
              and investment to
              <span className='emphasis-primary'>
                {' '}
                Birmingham
              </span>{' '}
              and{' '}
              <span className='emphasis-primary'>
                {' '}
                Tuscaloosa
              </span>
              .
              <br />
              <br />
              <span className='emphasis-secondary'>
                Brasher Holdings
              </span>{' '}
              brings an entreprenurial approach to{' '}
              <span className='emphasis-primary'>
                multi-family, student housing,
              </span>{' '}
              and{' '}
              <span className='emphasis-primary'>
                single-family development
              </span>
              .
            </div>
          </div>
        </div>
        <div id='leadership'>
          <div
            id='leadership-left-image'
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/c.png`,
            }}
          ></div>
          <div id='leadership-box'>
            <div id='leadership-box-content'>
              <div className='section-heading'>
                leadership
              </div>
              <div className='section-verbiage'>
                Our team has over{' '}
                <span className='emphasis-primary'>
                  45 years of experience
                </span>{' '}
                in acquisition, leasing, and development.
                <br />
                <br />
                Our team's passion is{' '}
                <span className='emphasis-primary'>
                  innovative
                </span>{' '}
                and{' '}
                <span className='emphasis-primary'>
                  unorthodox
                </span>{' '}
                thinking.
              </div>
            </div>
            <div id='leadership-bios-wrapper'>
              {leadership.map((leader) => (
                <div
                  className='leadership-bio'
                  key={leader.id}
                >
                  <div className='leadership-bio-primary'>
                    <div
                      className='leadership-bio-photo'
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/${leader.photoUrl}`,
                      }}
                    ></div>
                    <div className='leadership-bio-name'>
                      {leader.name.first}{' '}
                      <span className='leadership-bio-name-emphasis'>
                        {leader.name.last}
                      </span>
                    </div>
                    <div className='leadership-bio-email'>
                      <a href={`mailto:${leader.email}`}>
                        {leader.email}
                      </a>
                    </div>
                    <div className='leadership-bio-title'>
                      {leader.title}
                    </div>
                  </div>
                  <div className='leadership-bio-secondary'>
                    <div className='leadership-bio-description'>
                      {leader.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div id='contact'>
          <div id='contact-box'>
            <div
              id='contact-box-image'
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/d.png`,
              }}
            ></div>
            <div id='contact-box-content'>
              <div className='section-heading'>contact</div>
              <div className='contact-row'>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/call.png`}
                  className='contact-icon'
                  alt='Call icon'
                />

                <div className='contact-content'>
                  <a href='tel:2058431971'>
                    +1 205.843.1971
                  </a>
                </div>
              </div>
              <div className='contact-row'>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/email.png`}
                  className='contact-icon'
                  alt='Email icon'
                />
                <div className='contact-content'>
                  <a href='mailto:contact@brasherholdings.com'>
                    contact@brasherholdings.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='footer'>
          <div id='footer-content'>
            <div id='footer-address'>
              1501 University Boulevard #301 Tuscaloosa, AL
              35401
            </div>
            <div id='footer-copy'>
              © {new Date().getFullYear()} Brasher Holdings,
              LLC.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
